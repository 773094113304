<template>

<div v-if="settings.range_lanes_open == 1" id="range-container" class="main range" :class="sectionsClass">
	<div v-if="settings.range_calendar_hidden" class="range-wrapper empty-list">
		Range Times are Currently Unavailable.
	</div>
	<div v-else class="range-two-columns">
		<h1>Range Times</h1>
		<h3 class="range-calendar-subheader" v-if="membershipRow['membership_number']">Welcome {{ membershipRow['first_name'] }} {{ membershipRow['last_name'] }}</h3>
		<h3 class="range-calendar-subheader" v-else-if="settings.range_calendar_description" v-html="settings.range_calendar_description"></h3>
		<h3 class="range-calendar-subheader" v-else-if="selectedRangeProduct.members_only || settings.range_calendar_members_only"><router-link :to="myAccountLink" tag="a">Log In</router-link> to view our range times as a member or to create a membership.</h3>
		<h3 class="range-calendar-subheader" v-else>View our range times.</h3>
	</div>
	<div v-if="rangeProducts.length > 0 && !settings.range_calendar_hidden">
		<div class="range-meta">
			<div v-if="rangeProducts.length > 0" class="lanes-dropdown">
				<span class="date-label" style="margin: 0 1em 0 0;">Bay</span>
				<select v-if="rangeProducts.length > 1" v-model="selectedRangeProductId" style="font-size: 1.25em; height: 2em; padding: 0 0.25em;" @change="getRangeCalendar()">
					<option :value="rangeProduct.product_id" v-for="(rangeProduct, index) in rangeProducts">{{ rangeProduct.description }} &rang; ${{ rangeProduct.price}}</option>
				</select>
				<h2 v-else>{{ rangeProducts[0].description }}</h2>
			</div>
			<div class="date-select">
				<span class="date-label">Date</span>
				<span class="arrow prev" @click="changeDate(-1)" :class="{ disabled: reservationDate == today }">&lt;</span>
				<input type="date" :min="today" v-model.lazy="reservationDate">
				<span class="arrow next"  @click="changeDate(1)">&gt;</span>
			</div>
		</div>
	</div>

	<div v-if="!settings.range_calendar_hidden" class="range-wrapper">
		<div class="loading" v-if="loading">Loading...</div>
		<div class="range-scroller" v-else>
			<div class="range-grid" v-if="rangeTimes.length > 0">
				<table class="range-lanes" v-if="rangeTimes.length > 0">
					<tr>
						<th>Lanes</th>
						<th>Open</th>
						<th>Close</th>
					</tr>
					<tr v-for="rangeLane in rangeLanes">
						<td><div class="range-lane-label">{{ rangeLane.description }}</div></td>
						<td><div>{{ rangeTimes[0].description }}</div></td>
						<td><div>{{ lastTime }}</div></td>
					</tr>
				</table>
			</div>
			<div v-else class="empty-list">
				<span v-if="rangeProducts.length > 0">There are no range times available for<br>{{ selectRangeProductDescription }} on {{ $moment(reservationDate).format('MM/DD/YYYY') }}.<br>Try selecting a different bay or date.</span>
				<span v-else>There are no range times available for {{ $moment(reservationDate).format('MM/DD/YYYY') }}.<br>Try selecting a different date.</span>
			</div>

		</div><!-- .range-scroller -->
	</div><!-- .range-wrapper -->
</div>






<div v-else id="range-container" class="main range range-container" :class="sectionsClass">
	<div v-if="settings.range_calendar_hidden" class="range-wrapper empty-list">
		Range Time is Currently Unavailable
	</div>
	<div v-else class="range-two-columns">
		<h1>Reserve Range Time</h1>
		<h3 class="range-calendar-subheader" v-if="membershipRow['membership_number']">Welcome {{ membershipRow['first_name'] }} {{ membershipRow['last_name'] }}</h3>
		<h3 class="range-calendar-subheader" v-else-if="settings.range_calendar_description" v-html="settings.range_calendar_description"></h3>
		<h3 class="range-calendar-subheader" v-else-if="selectedRangeProduct.members_only || settings.range_calendar_members_only"><router-link :to="myAccountLink" tag="a">Log In</router-link> to reserve time as a member or to create a membership.</h3>
		<h3 class="range-calendar-subheader" v-else>You can reserve range time as a guest, or <router-link :to="myAccountLink" tag="a">Log In</router-link> to reserve time as a member or to create a membership.</h3>

	</div>
	<div v-if="rangeProducts.length > 0 && !settings.range_calendar_hidden">
		<div class="range-meta">
			<div v-if="rangeProducts.length > 0" class="lanes-dropdown">
				<select v-if="rangeProducts.length > 1" v-model="selectedRangeProductId" style="font-size: 1.25em; height: 2em; padding: 0 0.25em;" @change="getRangeCalendar()">
					<option :value="rangeProduct.product_id" v-for="(rangeProduct, index) in rangeProducts">{{ rangeProduct.description }} &rang; ${{ rangeProduct.price}}</option>
				</select>
				<h3 v-else>{{ rangeProducts[0].description }}</h3>
			</div>
			<div class="date-select">
				<span class="date-label">Date</span>
				<span class="arrow prev" @click="changeDate(-1)" :class="{ disabled: reservationDate == today }">&lt;</span>
				<input type="date" :min="today" v-model.lazy="reservationDate">
				<span class="arrow next"  @click="changeDate(1)">&gt;</span>
			</div>
			<div v-if="!settings.range_calendar_hidden" class="controls">
				<router-link to="/cart" tag="button" class="button action" v-if="rangeLanes.length > 0">Go to Cart</router-link>
			</div>
		</div>
		<div class="legend-labels">
			<span class="icon-label"><i class='fa fa-cart-plus'></i> Available</span>
			<span class="icon-label"><i class='fa fa-times'></i> Not Available</span>
			<span class="icon-label"><i class='fa fa-check'></i> In Cart</span>
		</div>
	</div>

	<div v-if="!settings.range_calendar_hidden" class="range-wrapper">
		<div class="loading" v-if="loading">Loading...</div>
		<div class="range-scroller" v-else>
			<div class="range-grid" v-if="rangeTimes.length > 0">
				<table class="range-lanes" v-if="rangeTimes.length > 0">
					<tr>
						<th class="col sticky-column">Time</th>
						<th class="col" v-for="(rangeLane, index) in rangeLanes">{{ rangeLane.description }}</th>
					</tr>
					<tr v-for="rangeTime in rangeTimes" class="res-grid">
						<td class="row sticky-column"><div class="range-lane-label">{{ rangeTime.description }}</div></td>
						<td class="col" v-for="(rangeLane,index) in rangeLanes">
							<div v-if="isReserved(rangeLane.range_lane_id,rangeTime.time)" class='item-picker disabled'><i class='fa fa-times'></i></div>
							<div v-else class='item-picker' :class="[isInCart(rangeLane.range_lane_id,rangeTime.time) ? 'in-cart' : 'item-picker']" @click="addToCart(rangeLane.range_lane_id,rangeTime.time)"><i :class="['fa', isInCart(rangeLane.range_lane_id,rangeTime.time) ? 'fa-check' : 'fa-cart-plus']"></i></div>
						</td>
					</tr>
				</table>
			</div>
			<div v-else class="empty-list">
				There are currently<br>no range times available.
			</div>

		</div><!-- .range-scroller -->
	</div><!-- .range-wrapper -->
	
	<div class="range-meta">
		<div v-if="!settings.range_calendar_hidden" class="controls">
			<router-link to="/cart" tag="button" class="button action" v-if="rangeLanes.length > 0">Go to Cart</router-link>
		</div>
	</div>
</div>

</template>

<script>
import moment from 'moment'
export default {
	name: 'range',
	data() {
		return {
			loading: false,
			monthNames: ['January','February','March','April','May','June','July','August','September','October','November','December'],
			daysOfTheWeek: ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'],
			day: '',
			month: '',
			year: '',
			minDate: '',
			maxDate: '',
			selectingDate: false,
			selectedDate: '',
			reservationDate: '',
			rangeProducts: [],
			selectedRangeProductId: '',
			selectedRangeProduct: '',
			membersOnly: false,
			membershipRow: {},
			productId: '',
			rangeLanes: [],
			rangeHours: [],
			rangeTimes: [],
			rangeReserved: [],
			rangeInCart: [],
			columnIndex: 0,
			settings: [],
			startTime: '',
			endTime: '',
			lastTime: ''
		}
	},
	created() {
		this.reservationDate = this.$route.query.reservation_date || this.today
		let d = new Date();
		this.minDate = (d.getYear() + 1900) + '-' + String(d.getMonth() + 1).padStart(2,'0') + '-' + String(d.getDate()).padStart(2,'0')
		d.setDate(d.getDate() + 90); // max date will be 90 days from today
		this.maxDate = (d.getYear() + 1900) + '-' + String(d.getMonth() + 1).padStart(2,'0') + '-' + String(d.getDate()).padStart(2,'0')
		this.productId = this.$route.query.product_id || ''
		this.fetchData()
	},
	computed: {
		today() {
			let date = new Date()
			let month = date.getMonth() + 1
			return date.getFullYear() + "-" + month.toString(10).padStart(2, "0") + "-" + date.getDate().toString(10).padStart(2, "0")
		},
		sectionsClass() {
			if (!this.getTemplateData.siteOptions) return
			return [
				(this.getTemplateData.siteOptions.fullWidth) ? 'full-width' : ''
			]
		},
        myAccountLink() {
            return this.getUser.user_name ? '/myaccount/profile' : '/myaccount/login'
        },
		registrationIsClosed() {
			let selectedDateParts = this.datesSelected[0].split(' ')
			let dateParts = selectedDateParts[0].split('/')
			let dateObject = new Date(dateParts[2], dateParts[0] - 1, dateParts[1])
			if (this.detail.registration_closes_days_before) {
				dateObject.setDate(dateObject.getDate() - this.detail.registration_closes_days_before)
			}
			return dateObject.getTime() < this.showDate.getTime()
		},
		selectRangeProductDescription() {
			if (this.rangeProducts.length > 0) {
				for (let i = 0; i < this.rangeProducts.length; i++) {
					if (this.rangeProducts[i].product_id == this.selectedRangeProductId) {
						return this.rangeProducts[i].description
					}
				}
			}
			return ''
		}
	},
	methods: {
		fetchData() {
			this.loading = true
			this.apiCall({ destination: 'get_list_range_products_frontend' }).then(obj => {
				this.loading = false
				this.settings = obj.settings
				if (obj.range_products.length > 0) {
					this.rangeProducts = obj.range_products
					this.membershipRow = obj.membership_row
					if (sessionStorage.getItem('selected_range_product')) {
						this.selectedRangeProductId = sessionStorage.getItem('selected_range_product')
					} else {
						this.selectedRangeProductId = this.rangeProducts[0].product_id
					}
					let membersOnlyProductCount = 0
					for (let i = 0; i < this.rangeProducts.length; i++) {
						membersOnlyProductCount += (this.rangeProducts[i].members_only ? 1 : 0)
					}
					this.membersOnly = this.rangeProducts.length == membersOnlyProductCount
					if (!this.productId) {
						this.productId = this.rangeProducts[0].product_id
					}
					this.getRangeCalendar()
				}
			}).catch(err => {
				console.error(err);
			})
		},
		getRangeCalendar() {
			this.loading = true
			let data = {
				product_id: this.selectedRangeProductId,
				reservation_date: this.reservationDate || this.today
			}
			this.apiCall({ destination: 'get_range_calendar', data: data }).then(obj => {
				this.rangeLanes = obj.range_lanes
				this.rangeHours = obj.range_hours
				this.rangeTimes = obj.range_times
				this.rangeReserved = obj.range_reserved
				this.rangeInCart = obj.range_in_cart
				this.loading = false
				this.lastTime = this.rangeTimes[this.rangeTimes.length - 1].description
			}).catch(error => {
				console.log(error)
			})
		},
		showColumn(index) {
			if (window.matchMedia("(min-width: 768px)").matches) {
				return true
			} else {
				return index == this.columnIndex || index == this.columnIndex + 1
			}
		},
		isInCart(rangeLaneId, reservationDate) {
			for (var i = 0; i < this.rangeInCart.length; i++) {
				if (this.rangeInCart[i]['range_lane_id'] == rangeLaneId && this.rangeInCart[i]['reservation_date'] == reservationDate) {
					return true
				}
			}
			return false
		},
		isReserved(rangeLaneId, reservationDate) {
			if (this.membersOnly && !this.membershipRow.membership_id) {
				return true
			}
			for (let i = 0; i < this.rangeProducts.length; i++) {
				if (this.rangeProducts[i].product_id == this.productId) {
					if (this.rangeProducts[i].members_only && !this.membershipRow.membership_id) {
						return true
					}
					break;
				}
			}
			for (let i = 0; i < this.rangeReserved.length; i++) {
				if (this.rangeReserved[i]['range_lane_id'] == rangeLaneId && this.rangeReserved[i]['reservation_date'] >= this.$moment(reservationDate).subtract('15', 'minutes').format('YYYY-MM-DD HH:mm:ss') && this.rangeReserved[i]['reservation_date'] <= this.$moment(reservationDate).add('15', 'minutes').format('YYYY-MM-DD HH:mm:ss')) {
					return true
				}
			}
			return false
		},
		addToCart(rangeLaneId, reservationDate) {
			if (this.isReserved(rangeLaneId,reservationDate)) {
				return false
			}
			let data = {
				action: this.isInCart(rangeLaneId,reservationDate) ? 'remove' : 'add',
				range_lane_id: rangeLaneId,
				reservation_date: reservationDate,
				product_id: this.selectedRangeProductId,
				duration: this.rangeHours.block_size
			}
			this.apiCall({ destination: 'add_range_time_to_cart', data: data }).then(obj => {
				if (obj.status == 'error') {
					console.log(obj.message)
				} else {
					this.getRangeCalendar()
        			this.fetchShoppingCartCount()
				}
			}).catch(err => {
				console.log(err)
			})
		},
		changeDate(step) {
			sessionStorage.setItem('selected_range_product', this.selectedRangeProductId)
			if (this.reservationDate == this.today && step == -1) return
			let reservationDate = this.reservationDate.split(' ')
			let date = reservationDate[0].split('-')
			let day = parseInt(date[2])
			day = day + step
			reservationDate = date[0] + '-' + date[1] + '-' + day.toString().padStart(2, '0')
			this.$router.replace({ path: '/range', query: { product_id: this.$route.query.product_id, reservation_date: reservationDate }})
		}
	}
}
</script>

<style lang="scss">
#range-container {
	.loading { font-size: 2em; margin: 2em 0; text-align: center; }
	table.range-lanes {
		width: 100%;
		overflow: hidden;
		position: relative;
		td, th, .row, .col {
			position: relative;
		}
		th { border: solid #999; border-width: 1px; background-color: #656565; color: #fff; vertical-align: middle; white-space: nowrap; padding: 10px 0;}
		tr.res-grid:hover { background-color: rgba(88, 164, 7, 0.25); }
		td { 
			border: solid #999; 
			border-width: 0 1px 1px 1px; 
			text-align: center;
			i {
				font-size: 1.25em;
			}
		}
		td:nth-of-type(1), th:nth-of-type(1) { border-left-width: 1px; }
		.range-lane-label { line-height: 2.5em; padding: 0 1em; background-color: #cdcdcd; }
		.item-picker { line-height: 2.5em; cursor: pointer; width: 25vw; }
		.item-picker:hover { background-color: rgba(88, 164, 7, 0.5); }
		.disabled { cursor: not-allowed; background-color: rgba(123, 129, 129, 0.7)}
		.disabled:hover { background-color:  rgba(123, 129, 129, 0.7); }
		.in-cart { background-color: rgba(88, 164, 7, 0.7); }
		.in-cart:hover { background-color: rgba(150, 43, 33, 1); color: #fff; }
		.col:hover::after { 
			background-color: rgba(88, 164, 7, 0.25);
			content: '\00a0';  
			height: 10000px;    
			left: 0;
			position: absolute;  
			top: -1000px;
			width: 100%;
			z-index: -1;   
			overflow: hidden;     
		}

		@media all and (min-width: 768px) {
			width: 100%;
			.range-lane-label { width: 100%; }
			.item-picker { width: 100%; }
		}
	}
}

.range-wrapper {
	position: relative;
	align-self: self-start;
	overflow: hidden;
	margin: 1em 0 2em 0;
}

.range-scroller {
	overflow-x: scroll;
}

.range-grid {
	width: 100%;
}


.range-meta {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 1em;
	margin: 1em 0;
	padding-bottom: 1em;
	border-bottom: 1px solid #000;
	.date-select {
        display: grid;
        grid-template-columns: 3em 2em 11em 2em;
        align-items: center;
		// justify-items: center;
		.date-label { font-size: 18px; }
		input {
		    padding: 5px 10px;
			font-family: sans-serif;
			font-size: 16px;
			justify-self: center;
		}
	    .arrow {
		    font-size: 24px !important;
		    font-weight: bold;
		    cursor: pointer;
		}
		.prev {
			margin: 0 5px;
			justify-self: right;
		}
		.prev.disabled { cursor: not-allowed; }
		.next {
			margin: 0 5px;
			justify-self: left;
		}
	}
	// .controls {
	// 	grid-column: 3/4;
	// 	justify-self: end;
	// }
}

.legend-labels {
	width: 50%;
	background-color: #fff;
	text-align: center;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 10px;
	margin: 0 auto;
	padding: 5px;
	border-radius: 10px;
	i { 
		display: inline-block;
		font-size: 1.25em;
		padding-right: 5px;
	}
}

@media (max-width: 850px) {
	#range-container {
		table.range-lanes {
			overflow: scroll;
			td {
				white-space: nowrap;
			}
			td.col {
				padding: 0 25px;
			}
		}
	}
	.range-meta {
		grid-template-columns: 1fr 1fr;
	}
	.legend-labels {
		width: 75%;
	}
	#range-container {
		td.sticky-column, th.sticky-column {
			position: sticky !important;
			z-index: 2;
			left: 0;
		}
	}
}

@media (max-width: 650px) {
	.range-meta {
		grid-template-columns: 1fr;
		justify-items: center;
	}
	.legend-labels {
		width: 100%;
		i {
			display: block;
		}
	}
}
// .lanes-dropdown {
// 	text-align: left; 
// 	padding: 0 1.25em;
// }
// .range-two-columns {
// 	grid-template-columns: 1fr 1fr;
// }
// .icon-label {
// 	i {display: block;}
// }

// @media (max-width: 850px) {
// 	.range {
// 		grid-template-rows: auto;
// 		grid-row-gap: 0.5em;
// 		.range-two-columns {
// 			grid-template-columns: 1fr;
// 		}
// 		.lanes-dropdown {
// 			text-align: left;
// 		}
// 	}
// }

// @media (max-width: 1024px) {
// 	.range {
// 		.meta {
// 			grid-template-columns: 1fr 1fr 1fr 1fr;
// 			grid-template-rows: 1fr;
// 			.date-select {
// 				grid-column: auto;
// 			}
// 		}
// 	}
// }
// @media (max-width: 650px) {
// 	.range {
// 		.meta {
// 			grid-template-columns: 1fr 1fr 1fr;
// 			grid-gap: 0.5em;

// 			.lanes-dropdown {
// 				grid-column: 1/4;
// 			}
// 			.date-select {
// 				grid-column: 1/4;
// 			}
// 		}
// 	}
// }
</style>
